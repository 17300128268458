





























































































































































import { Component } from "vue-property-decorator";
import Draggable from "vuedraggable";
import { cloneDeep, isEqual } from "lodash";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import IconButton from "@/components/design-system/buttons/IconButton.vue";
import IconTooltip from "@/components/design-system/icons/IconTooltip.vue";
import SimpleComfirmationModal from "@/components/design-system/modals/SimpleConfirmationModal.vue";
import { serviceManager } from "@/services/settings-services/service-manager";
import sidebarConstants from "@/shared/constants/sidebar-settings";
import options from "@/shared/constants/toast-options";
import { WorktagFieldSetting } from "@/interfaces/settings/worktag-field";

@Component({
  components: {
    draggable: Draggable,
    "primary-button": PrimaryButton,
    "icon-button": IconButton,
    "icon-tooltip": IconTooltip,
    "simple-confirmation-modal": SimpleComfirmationModal
  }
})
export default class WorktagFields extends UserRolesMixin {
  // reactive properties

  private isSaveChangesInProgress = false;
  private isMoveWorktagModalDisplayed = false;
  private isEditRequiredExpressionModalDisplayed = false;
  private requiredExpression = "";
  private requiredExpressionRules: Function[] = [
    (expression: string) => expression.length <= 2000,
    (expression: string) => expression.length > 0
  ];
  private selectedWorktag: WorktagFieldSetting | null = null;
  private allWorktags: WorktagFieldSetting[] = [];
  private allWorktagsInitialState: WorktagFieldSetting[] = [];

  private worktagFieldsTableHeaders = [
    {
      align: "left",
      filterable: false,
      text: "Order",
      value: "displayOrder"
    },
    {
      align: "left",
      filterable: false,
      text: "Worktag Name",
      value: "organizationType"
    },
    {
      align: "left",
      filterable: false,
      text: "Require",
      value: "requiredExpression"
    },
    {
      align: "left",
      filterable: false,
      text: "Action",
      value: "action"
    }
  ];
  private additionalWorktagsTableHeaders = [
    {
      align: "left",
      filterable: false,
      text: "Worktag Name",
      value: "organizationType"
    },
    {
      align: "left",
      filterable: false,
      text: "Action",
      value: "action"
    }
  ];

  // readonly properties

  private readonly trueVal = `"true"`;
  private readonly falseVal = `"false"`;

  private readonly settingService = serviceManager(
    sidebarConstants.worktagFieldsSettings
  );

  // computed properties

  private get worktagFields(): WorktagFieldSetting[] {
    return this.allWorktags
      .filter(worktag => worktag.breakout)
      .sort((a, b) => a.displayOrder - b.displayOrder);
  }

  private get additionalWorktags(): WorktagFieldSetting[] {
    return this.allWorktags.filter(worktag => !worktag.breakout);
  }

  private get isSaveDisabled(): boolean {
    // allow saving when current worktag setting values differ from initial state
    return (
      isEqual(this.allWorktags, this.allWorktagsInitialState) ||
      this.isSaveChangesInProgress
    );
  }

  private get isUpdateRequiredExpressionDisabled(): boolean {
    return (
      (this.selectedWorktag?.requiredExpression ?? "").trim() ===
      this.requiredExpression.trim()
    );
  }

  // lifecycle methods

  async created() {
    const apiResponse = await this.settingService.get();
    this.setInitialState(apiResponse);
  }

  // methods

  setInitialState(apiResponse: any, isUpdate = false) {
    if (apiResponse?.data?.success && apiResponse?.data?.breakoutSettings) {
      this.allWorktags = apiResponse.data.breakoutSettings;
      this.allWorktagsInitialState = cloneDeep(this.allWorktags);

      if (isUpdate) {
        this.$toasted.show(
          "<p>Worktag fields successfully saved</p>",
          options.SUCCESS_OPTIONS
        );
        this.isSaveChangesInProgress = false;
      }
    }
  }

  openMoveWorktagModal(worktag: WorktagFieldSetting) {
    this.selectedWorktag = worktag;
    this.isMoveWorktagModalDisplayed = true;
  }

  updateWorktagBreakoutStatus(worktag: WorktagFieldSetting) {
    // add displayOrder when breaking out worktag into its own field
    if (!worktag.breakout) {
      worktag.displayOrder = this.worktagFields.length + 1;
    }

    // move worktag between Worktag Fields and Additional Worktags tables
    worktag.breakout = !worktag.breakout;

    // move worktag back to Additional Worktags
    if (!worktag.breakout) {
      // find original state of worktag, so that changes in displayOrder when it was moved
      // to Worktag Fields does not hinder comparison of current state to initial state
      const originalWorktag: WorktagFieldSetting =
        this.allWorktagsInitialState.find(
          item => item.organizationType == worktag.organizationType
        ) ?? worktag;
      this.allWorktags.splice(this.allWorktags.indexOf(worktag), 1, {
        ...originalWorktag,
        breakout: false
      });
      // reorder Worktag Fields
      this.reorderWorktagFields();
    }

    // clear selected worktag and close modal (if open)
    this.selectedWorktag = null;
    this.isMoveWorktagModalDisplayed = false;
  }

  openEditRequiredExpressionModal(worktag: WorktagFieldSetting) {
    this.selectedWorktag = worktag;
    this.requiredExpression = worktag.requiredExpression;
    this.isEditRequiredExpressionModalDisplayed = true;
  }

  saveWorktagRequiredExpression(worktag: WorktagFieldSetting) {
    // set new value for worktag's requiredExpression value
    worktag.requiredExpression = this.requiredExpression;

    // clear selected worktag and close modal
    this.selectedWorktag = null;
    this.isEditRequiredExpressionModalDisplayed = false;
  }

  reorderWorktagFields() {
    for (let i = 0; i < this.worktagFields.length; i++) {
      this.worktagFields[i].displayOrder = i + 1;
    }
  }

  setWorktagFieldOrderFromDragDrop(worktags: WorktagFieldSetting[]) {
    worktags.forEach((worktag, index) => {
      worktag.displayOrder = index + 1;
    });
  }

  private async saveChanges() {
    this.isSaveChangesInProgress = true;
    const saveResponse = await this.settingService.save(this.allWorktags);
    this.setInitialState(saveResponse, true);
  }
}
