


















































import { Component } from "vue-property-decorator";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import { randomNumberGenerator } from "@/helpers/numbers-helpers";
import WorktagFields from "@/components/settings/WorktagFields.vue";

@Component({
  components: {
    "worktag-fields": WorktagFields
  }
})
export default class FieldSettings extends UserRolesMixin {
  // reactive properties

  private selectedCategoryIndex = 0;
  private itemsListKey = randomNumberGenerator();
  private categoriesToRender = [
    {
      name: "Worktag Fields",
      component: "worktag-fields"
    }
  ];

  // methods

  updateListToRender() {
    const firstListItem = this.categoriesToRender.splice(
      this.selectedCategoryIndex,
      1
    );
    this.categoriesToRender.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
    this.categoriesToRender = [...firstListItem, ...this.categoriesToRender];
    this.itemsListKey = randomNumberGenerator();
    this.selectedCategoryIndex = 0;
  }

  private setSelectedCategoryIndex(index: number) {
    this.selectedCategoryIndex = index;
    this.updateListToRender();
  }
}
